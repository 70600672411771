// Define all style variables here.

// Colours.
$white: #FFFFFF;
$gray: #EFEFF0;
$black: #5F686A;

// Brand Colours.
$color-primary: #E87200;
$color-secondary: #98989A;

// Fonts
@import url("https://use.typekit.net/kbh1vwg.css");
$heading: 'montserrat', sans-serif;
$font: 'montserrat', sans-serif !default;


// Media querys.
$small: "(max-width: 767px)";
$mobile: "(min-width: 750px)";
$tabletmid: "(max-width: 999px) and (min-width: 768px)";
$tablet: "(min-width: 1000px)";
$desktop: "(min-width: 1500px)";
$large: "(min-width: 1600px)";

// Misc.
$max-width: 1000px;
$shadow: 0 5px 10px 0 rgba(0,0,0,.05);
$ease: ease all 0.3s;
$transition-time: 750ms;

// Default classes, add these to elements to prevent repeating yourself.
.default-background {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.default-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
 
// SCSS includes.
@mixin default-psuedo {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  transition: $ease;
}

