// Default element styles and main containers.

@import "vars";

* {
	padding: 0px;
	margin: 0px;
	box-sizing: border-box;
	color: $color-primary;
	font-family: $font;
}

body,
html {
	height: 100%;
}

.row {
    margin: 0;
    padding: 0;
}

p {
	font-size: 20px;
    text-transform: initial;
    font-weight: 500;
    line-height: 30px;
	color: #5F686A;
	@media #{$small} {
		font-size: 17px;
	}
}


h1,
h2 {
	line-height: 1.25;
    text-transform: initial;
	font-family: $heading;
	text-align:center;
	color: $color-primary;
	font-weight:500;
	@media #{$small} {
		font-size: 35px;
	}
}

h1, h2 {
	font-size: 50px;
	font-family: $heading;
	color: $color-primary;
    text-transform: initial;
	@media #{$small} {
		font-size: 40px;
	}
}

h3,
h4,
h5,
h6 {
	font-size: 40px;
    font-family: $heading;
    font-weight: normal;
	color: $color-primary;
	text-transform: inherit;
	font-weight: 500;
	letter-spacing: 0px;
	@media #{$small} {
		font-size: 30px;
	}
}

a {
	transition: $ease;
	text-decoration: none;
	&:hover {
		text-decoration: none;
	}
}

ul {
	list-style: none;
}

span,
u,
b,
i,
a,
strong,
em,
blockquote {
	color: inherit;
	font-size: inherit;
	font-family: inherit;
	font-weight: inherit;
}


i,
em,
blockquote {
	font-style: italic;
}

b,
strong {
	font-weight: 700;
	@media #{$small} {
		font-size: 20px;
	}
}

img {
	object-fit: cover;
}

.primary-container {
	width: 90vw;
	max-width: $max-width;
	margin: 0 auto;
	padding: 0em 0em;
	@media #{$small} {
		width: 100%;
	}
	&.block {
		margin-bottom: 0px;
	    padding: 0;
		@media #{$tablet} {
			margin-bottom: 0px;
			padding: 0;
		}
	}
}

.primary-text {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	h1,
	h2,
	h3,
	h4,
	p,
	ul,
	ol,
	blockquote,
	img {
		width: 100%;
		margin-bottom: 10px;
		@media #{$tablet} {
			width: 100%;
		}
		&:last-child {
			margin-bottom: 0px;
		}
		a {
			text-decoration: underline;
			&:hover {
				color: $color-primary;
			}
		}
	}
}

.content {
	display: block;
	@media #{$tablet} {
		padding-bottom: 0px;
	}
}

// LOGO
.logo {
	display: block;
	&:hover {
		transform: scale(1.1);
	}
	img {
		// width: 300px;
		height: auto;
		@media #{$small} {
			width: 70%;
			position: relative;
			left: 0em;
		}
	}
}

// @media only screen and (min-width: 400px) and (max-width: 750px)  {
// 	.logo img {
// 		width:75% !important;
// 		position: relative;
// 		left: 0;
// 		margin-top: -10px !important;
// 	}
// }


// Button base styles and additional classes here.
// HOVER MAIN
.content__item {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
	z-index: 1;
	pointer-events: none;
}

.content__item::before {
	color: $color-secondary;
	position: absolute;
	top: 0;
	left: 0;
}

.button {
	pointer-events: auto;
	cursor: pointer;
	background: transparent;
	border: none;
	margin: 0;
	font-family: inherit;
	position: relative;
	display: inline-block;
	padding: 1em 3em;
	text-transform:inherit;
	color: $white;
	font-size:18px;
	font-family: $heading;
	margin-top: 0em;
	background: $color-secondary;
	font-weight:500;
	transition: $ease;
	border-radius:2em;
	@media #{$small} {
		padding: 1em 2em;
		font-size: 17px;
	}
	&:hover {
		transition: $ease;
		font-weight:bold;
	}
}
 


.button::before,
.button::after {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}


button.btn.btn-primary {
    color: #fff;
    padding: 10px 30px;
    border-radius: 2em;
    margin-right: 1em;
    font-size: 35px;
    background: $color-primary;
    border: none;
	opacity: 1;
	font-weight: bold;
	transition: $ease;
	@media #{$small} {
		margin-right: 0em;
		font-size: 18px;
		margin-bottom:1em;
	}
	@media only screen and (min-width: 768px) and (max-width:1199px)  {
		font-size: 17px;
	}
	&:hover {
		background: $color-secondary;
		transition: $ease;
	}
}




button.btn.btn-secondary {
    font-size: 30px;
    font-weight: bold;
    border-radius: 2em;
    padding: 12px 30px;
    border: none;
	transition: $ease;
	opacity: 1;
	@media #{$small} {
		margin-right: 0em;
		font-size: 18px;
	}
	@media only screen and (min-width: 768px) and (max-width: 1199px)  {
		font-size: 15px;
	}
	&:hover {
		background: $color-secondary;
		transition: $ease;
	}
}


@media only screen and (max-width: 767px) {
    .btn-group, .btn-group-vertical {
      position: relative;
      display: inline-grid;
      vertical-align: middle;
  }

  div#title {
    margin-top: 0 !important;
	top: 75%;
}
  }
// All footer styles.

footer {
	    padding: 0;
		color: $white;
		background-color: #5F686A;
		background-repeat: no-repeat;
		background-position: top right;
		background-size: contain;
	a {
		color: $white;
	}
	span {
		color:$color-primary;
		font-family: $font;
		display: flex;
	}
	p {
		color: $white;
		text-align: center;
	}
	.footer-main,
	.footer-bottom {
		width: 100%;
		max-width: 100%;
		padding: 5px 0px;
		text-align:center;
	}
	.footer-main {
		padding: 2.5em 0px;
		padding-bottom: 0px !important;
		@media #{$small} {
			padding-bottom:0;
		}
		.primary-container {
			display: flex;
			flex-direction: column;
			max-width:1200px;
			padding-bottom: 0;
			@media #{$tablet} {
				flex-direction: column;
				justify-content: space-between;
				margin: auto;
				text-align: center;
			}
			.logo {
				padding-bottom:1.5em;
				img {
				width: 20%;
				height: auto;
				@media #{$small} {
					width: 50%!important;
				}
		      }
	     	}

			 ul.sub-menu {
				display: none;
			}
			.logo-text,
			.location,
			.social {
				width: 100%;
				justify-content: center;
				margin: auto;
				@media #{$tablet} {
					margin-bottom: 0px;
				}
			}
			.logo-text {
				@media #{$tablet} {
					display: flex;
					flex-direction: column;
					align-items: flex-end;
				}
				.logo {
					margin-bottom: 20px;
					margin: auto;
				}
				.text {
					color: $white;
				}
			}
			.location {
				margin-bottom: 10px;
				p {
					font-size: 20px;
					color: $color-primary;
					margin-bottom: 0px;
					text-align: center;
				}
				.location-container {
					width: 100%;
					color: $white;
					line-height: 30px;
					margin: auto;
					a {
						font-size:20px;
					@media #{$small} {
						display: block;
				    	}
			    	}
				}
			}
			.social {
				display: block;
				align-items: flex-start;
				left: -10px;
				position: relative;
				padding-top:1em;
				@media #{$small} {
				padding-top: 1em;
				padding-bottom: 1em;
				}
				img {
					width: 45px;
					height: 45px;
					object-fit: contain;
					transition: $ease;
					&:hover{
						transform: scale(1.2);
						transition: $ease;
					}
				}
				p {
					font-weight: bold;
					margin-bottom: 10px;
					font-size: 20px;
					color: $white;
				}
				.social-icons {
					width: 100%;
					display: flex;
					margin-bottom: 30px;
					a {
						margin-right: 10px;
						width: 40px;
						height: 40px;
						background-size: contain;
						&:last-child {
							margin-right: 0px;
						}
						&:hover {
							transform: scale(1.1);
						}
					}
				}
			}
			.location,
			.social {
				margin:auto;
			}
		}
	}
	.footer-bottom {
		padding: 1em 0 1em 0em;
		background: #5f686a;
		border-top:1px solid $white;
		@media #{$small} {
			padding-top:0 !important;
		}
		.links-footer {
			margin: auto;
			@media #{$small} {
				margin: auto;
				padding-top: 1em;
			}
		}
		a {
			color: $white;
			&:hover {
				text-decoration: none;
			}
		}
		.primary-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			@media #{$tablet} {
				flex-direction: row;
				justify-content: space-between;
			}
			.copyright {
				color: $white;
				margin-bottom: 10px;
				opacity: 0.5;
				text-align: center;
				@media #{$tablet} {
					margin-bottom: 0px;
					margin-right: 0px;
					width: auto;
					text-align: left;
				}
			}
			.signature {
				display: flex;
				align-items: center;
				opacity: 0.5;
				&:hover {
					opacity: 1;
				}
				img {
					width: 215px;
					height: auto;
				}
			}
		}
	}
}

a.email,
a.number {
    text-align: left;
    float: left;
    font-size: 20px;
}


.address {
    text-align: center;
    justify-content: center;
    display: flex;
    margin: auto;
}

a.yellow_link {
    font-size: 22px;
    font-weight: 600;
    color: $color-secondary;
	&:first-child {
		padding-right: 1em;
		@media #{$small} {
			padding-right: 0em;
		}
	}
	&:hover {
		color: $white;	
	}
	strong {
		color: $white;
	}
}

.copyright_section {
    padding-top: 2em;
	padding-bottom:2em;
	@media #{$small} {
		padding-top: 0em;
	}
	a {
		color: $color-primary;
		&:hover {
			text-decoration: underline;
		}
	}
	p {
		padding-bottom: 1em;
	}

}


p.email_us span, a {
    display: inline-block;
	@media #{$small} {
		display:block;
	  }
}

p.email_us {
	white-space: nowrap;
    margin: 0;
	span, a {
		display: inline-block;
		@media #{$small} {
			display: block;
		}
	}
	span {
		padding: 0px 5px;
		font-weight: 500;
	}
} 

.location strong {
	font-size: 20px;
	font-weight: bold;
	color: $white;
	padding-bottom:5px;
}



ul.footer_links {
    display: flex;
    margin: auto;
    justify-content: center;
    padding-left: 0;
	margin-top:1.5em;
	padding-bottom:1em;
 	i.fab.fa-instagram {
    padding: 10px;
	font-size: 40px;
	transition: $ease;
		&:hover {
			transform: scale(0.9);
			transition: $ease;
		}
	}
	i.fab.fa-facebook-f{
		padding: 10px;
		font-size: 40px;
		transition: $ease;
		&:hover {
			transform: scale(0.9);
			transition: $ease;
		}
	}
	i.fab.fa-linkedin-in {
		padding: 10px;
		font-size: 40px;
		background: $white;
		transition: $ease;
		&:hover {
			transform: scale(0.9);
			transition: $ease;
		}
	}
	li {
		margin: 0px 5px;
		margin-bottom: 1.5em;
		margin-top: 0;
	}
	i.fab.fa-twitter {
		padding: 10px;
		font-size: 40px;
		transition: $ease;
		&:hover {
			transform: scale(0.9);
			transition: $ease;
		}
	}
}

div#gform_wrapper_1 {
    padding: 3em;
    padding-top: 0;
    width: 70%;
    margin: auto;
    @media #{$small} {
        padding:1em !important;
        width: 100%;
    }
    @media (min-width: 2000px) {
        width: 55%;
        margin: auto;
    }
}


.gform_wrapper.gravity-theme .gfield textarea.large {
    @media (min-width: 768px) {
    height: 200px !important;
    }
}

.gform_wrapper.gravity-theme {
    .gform_fields {
    grid-row-gap: 2px !important;
    }
}

.gform_wrapper.gravity-theme input[type=color], .gform_wrapper.gravity-theme input[type=date], .gform_wrapper.gravity-theme input[type=datetime-local], .gform_wrapper.gravity-theme input[type=datetime], .gform_wrapper.gravity-theme input[type=email], .gform_wrapper.gravity-theme input[type=month], .gform_wrapper.gravity-theme input[type=number], .gform_wrapper.gravity-theme input[type=password], .gform_wrapper.gravity-theme input[type=search], .gform_wrapper.gravity-theme input[type=tel], .gform_wrapper.gravity-theme input[type=text], .gform_wrapper.gravity-theme input[type=time], .gform_wrapper.gravity-theme input[type=url], .gform_wrapper.gravity-theme input[type=week], .gform_wrapper.gravity-theme select, .gform_wrapper.gravity-theme textarea {
    font-size: 13px;
    padding: 8px;
    margin-bottom: 0;
    margin-top: 0;
    border: none;
    font-weight: bold;
    font-family: $font !important;
    background: #F4F4F4;
    border-radius: 2em;
}

.gform_wrapper.gravity-theme .gfield input.large, .gform_wrapper.gravity-theme .gfield select.large {
    padding: 1em;
    border-radius: 2em;
}


.gfield_label {
    color: $white !important;
    text-align: left !important;
    float: left;
    padding-top: 15px !important;
}

::-webkit-input-placeholder { color:$color-secondary; }
:-moz-placeholder { opacity: 1; color:$color-secondary; } /* Firefox 18- */
::-moz-placeholder { opacity: 1; color:$color-secondary;} /* firefox 19+ */
:-ms-input-placeholder { color:$color-secondary;} /* ie */
input:-moz-placeholder { color:$color-secondary;} 


.gform_wrapper.gravity-theme .gfield-choice-input+label {
    max-width: calc(100% - 32px);
    margin-bottom: 0;
    vertical-align: middle;
    font-weight: 600;
    padding: 0.5em;
    font-size: 13px;
}

div.gfield_radio input[type=radio]:checked {
    filter: brightness(0.5);
}

input[type="radio" i] {
    background-color: $color-secondary;
}

input#gform_submit_button_1 {
    margin: auto;
    padding: 1em 3em;
    display: flex;
    transition: $ease;
    background: $color-primary;
    @media #{$small} {
        padding: 0.5em 3em;
    }
    &:hover {
        background: $color-secondary;
        color: $white;
        transition: $ease;
    }
}


.gform_wrapper.gravity-theme .gfield input.large, .gform_wrapper.gravity-theme .gfield select.large {
    width: 100%;
    border-radius: 0;
    margin-bottom: 10px;
    background: #fff;
}

.ginput_container.ginput_container_text ::placeholder {
    color:#464545; /* Replace #ff0000 with your desired color code */
}

.ginput_container.ginput_container_email ::placeholder {
    color: #464545; /* Replace #ff0000 with your desired color code */
}


.ginput_container.ginput_container_textarea ::placeholder {
    color: #464545; /* Replace #ff0000 with your desired color code */
}

.gform_wrapper.gravity-theme .gfield textarea.large {
    background: #fff;
    border-radius: 0;
    padding-left: 13px;
    padding-top: 15px;
}

.gform_footer.top_label {
    float: left;
}

.gform_wrapper input:focus,
.gform_wrapper textarea:focus,
.gform_wrapper select:focus {
    outline: none;
    border-color: none; 
}

div#gform_confirmation_message_1 {
    text-align: center;
}
// All header styles.

header {
  width: 100vw;
  max-width: 100%;
  background-color: transparent;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999;
    .header-top, .header-main {
      padding: 20px 0px;
      width: 100vw;
      max-width: 100%;
    }
}

.header-top {
  background-color: $color-secondary;
  display: none;
  @media #{$tablet} {
    display: block;
  }
    .primary-container {
      display: flex;
      justify-content: flex-end;
      position: relative;
      padding: .7em 0;
      @media #{$small} {
        padding-left: 2em;
      }
        .brand-name {
          display: none;
          width: auto;
          position: absolute;
          top: -5px;
          left: 0px;
          color: $white;
          transition: $ease;
          @media #{$tablet} {
            display: block;
          }
        }
        a {
          color: $white;
          margin-right: 0px;
          position: relative;
          margin-left: 40px;
          display: flex;
          align-items: center;
            &:before {
              left: -40px;
              width: 20px;
              height: 20px;
              background-size: contain;
              background-position: center;
              background-repeat: no-repeat;
              content: "";
              position: absolute;
              top: calc(50% - 10px);
            }
            &:hover {
              opacity: 1;
              color: $color-secondary;
              @media #{$tablet} {
                border: none;
              }
              @media screen and (max-width: 999px) { 
                color: $white;
              }
            }
        }
    }
}

.header-main {
  background: $white;
  position: absolute;
  transition: $ease;
    .primary-container {
      display: flex;
      justify-content: space-between;
      max-width: 1200px;
      @media #{$small} {
        margin: 0;
        padding-left:1.5em;
        padding-top:1.5em
      }
        .logo-cta {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 50%;
          @media #{$tablet} {
            width: auto;
          }
            .logo {
              margin-right: -65px;
              @media #{$tablet} {
                margin: 0px;
              }
            }
            .button {
              font-size: 10px;
              @media #{$tablet} {
                display: none;
              }
            }
        }
        .nav-cta {
          display: flex;
          justify-content: flex-end;
          align-items: center;
           nav {
              right: 100vw;
              z-index: 1;
              transition: $ease;
              display: block;
              position: absolute;
              top: 96px;
              width: 100vw;
              max-width: 100%;
              min-height: calc(100vh - 96px);
              @media screen and (max-width: 999px) { 
                position: absolute;
                top: 0px;
                min-height: 100vh;
                height:100%;
              }
              @media #{$tablet} {
                position: static;
                width: 100%;
                min-height: 0px;
                margin-right: 0px;
              }
                &.active {
                  right: 0vw;
                  color: $color-secondary;
                  @media screen and (max-width: 999px) {
                    background: $color-secondary;
                  }
                }
                ul {
                  width: 85vw;
                  margin: 0 auto;
                  display: flex;
                  align-items: flex-start;
                  flex-direction: column;
                  @media #{$tablet} {
                    width: 100%;
                    margin: 0;
                    flex-direction: row;
                    justify-content: flex-end;
                    align-items: center;
                    flex-wrap: wrap;
                  }
                  @media screen and (max-width: 999px) { 
                    width:100%;
                    padding:0;
                  }
                    li {
                      width: 100%;
                      @media #{$tablet} {
                        width: auto;
                        margin: 0px 0px;
                        margin-right: 25px;
                        &:last-child {
                          margin-right: 0;
                        }
                      }
                      @media screen and (max-width: 999px) { 
                        border-bottom: 1px solid $white;
                      }
                      @media only screen and (max-width: 1116px) and (min-width: 1000px) {
                        margin-right: 14px;
                      }
                        a {
                          color: #5F686A;
                          padding: 20px 0px;
                          border-bottom: solid 1px $white;
                          display: block;
                          text-align: center;
                          font-size: 20px;
                          font-weight:bold;
                          margin-top:1em;
                          @media only screen and (max-width: 999px) and (min-width: 767px) {
                            padding:15px;
                            color: $white;
                          }
                          @media #{$tablet} {
                            padding: 0px;
                            text-align: left;
                            border: none;
                          }
                          @media #{$small} {
                          color: $white;
                          }
                          @media only screen and (max-width: 999px) and (min-width: 767px) {
                            font-size: 14px;
                          }
                            &.current {
                              opacity: 1;
                              pointer-events: none;
                              color: $color-secondary !important;
                              @media screen and (max-width: 999px) { 
                                color: $white !important;
                              }
                            }
                            &:hover {
                              opacity: 1;
                              color: $color-secondary;
                              @media #{$tablet} {
                                border: none;
                              }
                              @media #{$small} {
                                color: $white;
                              }
                            }
                        }
                        &.menu-item-has-children {
                          position: relative;
                          @media #{$tablet} {
                            padding-right: 0px;
                          }
                            .dropdown-trigger {
                              width: 15px;
                              height: 15px;
                              content: "";
                              position: absolute;
                              right: 2px;
                              top: 22px;
                              background-size: contain;
                              background-position: center;
                              background-repeat: no-repeat;
                              transition: $ease;
                              cursor: pointer;
                              @media #{$tablet} {
                                top: calc(50% - 7.5px);
                                right: 0px;
                              }
                              @media screen and (max-width: 999px) {
                              right: 38px;
                              background-image: url('./images/down-arrow.png');
                            }
                            @media screen and (max-width: 999px) and (min-width: 768px) {
                              right: 85px;
                            }
                                &.active {
                                  transform: rotate(180deg);
                                }
                                &:hover {
                                  transform: scale(1.1);
                                    &.active {
                                      transform: scale(1.1) rotate(180deg);
                                    }
                                }
                            }
                            .sub-menu {
                              display: none;
                              @media #{$tablet} {
                                position: absolute;
                                border: none;
                                top: 20px;
                                right: -13px;
                                background-color: #ffd700cf;
                                color: #1a345c;
                                width: 400px;
                                padding: 0;
                                margin: 0;
                              }
                              li {
                                padding: .5em 0em;
                                border-bottom: 1px solid $white;
                                margin:0;
                                color: $white;
                                padding-left: 1em;
                                @media #{$small} {
                                  border-bottom: none;
                                }
                                @media screen and (max-width: 999px) { 
                                  border-bottom: none;
                                  padding:0;
                                }
                                &:hover {
                                  border:none;
                                }
                                &:last-child {
                                  border-bottom: none;
                                }
                                a {
                                  font-weight:600;
                                  transition: $ease;
                                  text-transform: inherit;
                                  padding:0em .5em;
                                  @media screen and (max-width: 999px) { 
                                    padding: 1em;
                                    font-weight:700;
                                  }
                                  &:hover {
                                    color: $color-primary !important;
                                    font-weight:bold;
                                    transition: $ease;
                                  }
                                }
                              }
                            } 
                        }
                    }
                }
            }
            .button {
              display: none;
              @media #{$tablet} {
                display: block;
              }
            }
        }

 // NAVIGATION TRIGGERS
 .nav-trigger {
  width: 40px;
  height: 40px;
  position: absolute;
  top: calc(50% - 10px);
  right: 9.5vw;
  transition: $ease;
  z-index: 2;
  background-size: contain;
  cursor: pointer;
  background-image: url('./images/hamburger.png');
  @media #{$tablet} {
    display: none;
  }
  &.active {
    background-image: url('./images/close.png');
    background-size: 85%;
    transition: $ease;
  }
  &:hover {
    transform: scale(1.1);
    transition: $ease;
    }
   }
  }
}

header {
  @media #{$tablet} {
    &.tidy {
      @media #{$small} {
        display: none;
      }
        .header-top {
          .brand-name {
            opacity: 1;
          }
        }
        .header-main {
          max-height: 0px;
          opacity: 0;
          pointer-events: none;
          padding: 0px;
        }
        &:hover {
          .brand-name {
            opacity: 0;
        }
          .header-main {
            max-height: none;
            opacity: 1;
            pointer-events: all;
            padding: 25px 0px;
        }
      }
    }
  }
  .social {
    justify-content: right;
    display: flex;
    align-items: baseline;
    float:right;
    width: auto;
    @media #{$tablet} {
      margin-bottom: 0px;
    }
    @media #{$small} {
      display:none;
    }
    img {
      width: 35px;
      height: 35px;
      object-fit: contain;
      transition: $ease;
      &:hover{
        transform: scale(1.2);
        transition: $ease;
      }
    }
  }
}

.social a img {
  width: auto;
  padding: 0em 3px;
}

.header-wrapper {
  margin-left: 0;
  margin: auto;
  text-align: center;
}

header.header-main {
  .yellow_link {
    font-size: 25px;
    font-weight: 600;
    color: $color-primary;
    float: right;
    padding-left: 1em;
    letter-spacing:1px;
    @media screen and (max-width: 999px) {
      display: none;
  }
    &:first-child {
      padding-right: 1em;
  }
  &:hover {
    color: $color-secondary;	
  }
  strong {
    color: $color-primary;
    }
  }
}


ul#menu-header {
  margin-bottom: 1em;
  margin-top: 0.5em;
}

li#menu-item-117 {
  @media #{$tablet} {
    padding-left: 1.3em;
  }
}

li.menu-item-has-children {
 &:after {
  content: "";
  width: 100%;
  height: 100%;
  background-image: url(./images/down-arrow.png);
  position: absolute;
  z-index: 9999999;
  background-repeat: no-repeat;
  background-position: right;
  margin-top: -21px;
  margin-left: 20px;
  @media screen and (max-width: 999px) { 
    display:none;
  }
  &.hover {
    transform: rotate(90deg);
   }
  }
 }

 .menu-header-container {
  @media screen and (max-width: 999px) { 
  padding-top: 5em;
  }
}

li#current_page_item {
  color: $color-secondary;

}

nav#menu {
  margin-top: 2em;
}

.current_page_item a {
  color: $color-primary !important;
}
// /* #Cursor
// ================================================== */

// .cursor,.cursor2,.cursor3{
// 	display: none
// }

// /* #Primary style
// ================================================== */

// .section {
// position: relative;
// width: 100%;
// display: block;
// }
// .over-hide{
// overflow: hidden;
// }
// .full-height {
// height: 100vh;
// }

// /* #Navigation
// ================================================== */

// .cd-header{
// position: fixed;
// width:100%;
// top:0;
// left:0;
// z-index:100;
// background: $white;
// } 
// .header-wrapper {
// position: relative;
// width: calc(100% - 100px);
// margin-left: 0px;
// display: flex;
// } 
// .logo-wrap {
// // position: absolute;
// display:block;
// left:0;
// top: 40px;
// cursor: pointer;
// @media #{$small} {
// top: 25px;
// }
// }
// .logo-wrap a {
// cursor: pointer;
// font-family: $font;
// font-weight: 900;
// font-size: 20px;
// line-height: 20px;
// text-transform: uppercase;
// letter-spacing: 2px;
// color: #fff;
// transition : all 0.3s ease-out;
// }
// .logo-wrap a span{ 
// color: #8167a9;
// }
// .logo-wrap a:hover {
// opacity: 0.9;
// }
// .nav-but-wrap{ 
// position: relative;
// display: inline-block;
// float: right;
// padding-left: 15px;
// padding-top: 15px;
// transition : all 0.3s ease-out;
// @media #{$small} {
// 	margin-top: 0em;
// 	padding-left:0;
// }
// }
// .menu-icon {
// height: 45px;
// width: 45px;
// position: relative;
// z-index: 2;
// cursor: pointer;
// display: block;
// @media #{$small} {
// 	width: 39px;
// }
// img {
// 	width: 100%;
// }
// }
// .menu-icon__line {
// height: 2px;
// width: 45px;
// display: block;
// background-color: $color-primary;
// margin-bottom: 7px;
// cursor: pointer;
// -webkit-transition: background-color .5s ease, -webkit-transform .2s ease;
// transition: background-color .5s ease, -webkit-transform .2s ease;
// transition: transform .2s ease, background-color .5s ease;
// transition: transform .2s ease, background-color .5s ease, -webkit-transform .2s ease;
// @media #{$small} {
// width: 39px;
// cursor: pointer;
// display: block;
// }
// }
// .menu-icon__line-left {
// width: 25px;
// -webkit-transition: all 200ms linear;
// transition: all 200ms linear;
// }
// .menu-icon__line-right {
// width: 22px;
// float: right;
// -webkit-transition: all 200ms linear;
// -moz-transition: all 200ms linear;
// -o-transition: all 200ms linear;
// -ms-transition: all 200ms linear;
// transition: all 200ms linear;
// }
// .menu-icon:hover .menu-icon__line-left,
// .menu-icon:hover .menu-icon__line-right {
// width: 45px;
// }
// .nav {
// position: fixed;
// z-index: 98;
// margin-top:-1em;
// }
// .nav:before, .nav:after {
// content: "";
// position: fixed;
// width: 100vw;
// height: 100vh;
// background: $black;
// border-bottom-left-radius: 200%;
// z-index: -1;
// -webkit-transition: -webkit-transform cubic-bezier(0.77, 0, 0.175, 1) 0.6s, border-radius linear 0.8s;
// transition: -webkit-transform cubic-bezier(0.77, 0, 0.175, 1) 0.6s, border-radius linear 0.8s;
// transition: transform cubic-bezier(0.77, 0, 0.175, 1) 0.6s, border-radius linear 0.8s;
// transition: transform cubic-bezier(0.77, 0, 0.175, 1) 0.6s, -webkit-transform cubic-bezier(0.77, 0, 0.175, 1) 0.6s, border-radius linear 0.8s;
// -webkit-transform: translateX(100%) translateY(-100%);
// 	  transform: translateX(100%) translateY(-100%);
// 	  @media #{$small} {
// 		height: 100%;
// 	  }
// }
// .nav:after {
// background: $color-primary;
// -webkit-transition-delay: 0s;
// 	  transition-delay: 0s;
// }
// .nav:before {
// -webkit-transition-delay: .2s;
// 	  transition-delay: .2s;
// }
// .nav__content {
// position: fixed;
// visibility: hidden;
// top: 50%;
// margin-top: 50px;
// -webkit-transform: translate(0%, -50%);
// 	  transform: translate(0%, -50%);
// width: 100%;
// text-align: center;
// @media #{$small} {
// 	margin-top:0px;
// }
// }
// .nav__list {
// position: relative;
// padding: 0;
// margin: 0;
// z-index: 2;
// }
// .nav__content {
// .menu-item {
// position: relative;
// display: block;
// -webkit-transition-delay: 0.8s;
// 	  transition-delay: 0.8s;
// opacity: 0;
// text-align: center;
// color: $white;
// overflow: hidden; 
// font-family: $font;
// font-size: 5vh;
// font-weight: 600;
// line-height: 1.15;
// letter-spacing: 3px;
// -webkit-transform: translate(100px, 0%);
// 	  transform: translate(100px, 0%);
// -webkit-transition: opacity .2s ease, -webkit-transform .3s ease;
// transition: opacity .2s ease, -webkit-transform .3s ease;
// transition: opacity .2s ease, transform .3s ease;
// transition: opacity .2s ease, transform .3s ease, -webkit-transform .3s ease;
// margin-top: 0;
// margin-bottom: 0;
// @media #{$small} {
// 	font-size: 27px;
// 	width: max-content;
// 	padding-left: 0.5em;
// }
// }
// .menu-item a{ 
// position: relative;
// text-decoration: none;
// color: rgba(255,255,255,0.6);
// overflow: hidden; 
// cursor: pointer;
// padding-left: 5px;
// padding-right: 5px;
// font-weight: 900;
// z-index: 2;
// display: inline-block;
// text-transform: uppercase;
// -webkit-transition: all 200ms linear;
// transition: all 200ms linear; 
// @media #{$small} {
// 	color: $white;
// }
// }
// .menu-item a:after{ 
// position: absolute;
// content: '';
// top: 50%;
// margin-top: -2px;
// left: 50%;
// width: 0;
// height: 0;
// opacity: 0;
// z-index: 1;
// -webkit-transition: all 200ms linear;
// transition: all 200ms linear; 
// }
// .menu-item a:hover:after{ 
// height: 4px;
// opacity: 1;
// left: 0;
// width: 100%;
// }
// .menu-item a:hover{
// color: $color-secondary;
// }
// .menu-item.active-nav a{
// color: rgba(255,255,255,1);
// }
// .menu-item.active-nav a:after{ 
// height: 4px;
// opacity: 1;
// left: 0;
// width: 100%;
// }
// }
// body.nav-active .nav__content {
// visibility: visible;
// }
// body.nav-active .menu-icon__line {
// background-color: $color-primary;
// -webkit-transform: translate(0px, 0px) rotate(-45deg);
// 	  transform: translate(0px, 0px) rotate(-45deg);
// }
// body.nav-active .menu-icon__line-left {
// width: 25px;
// -webkit-transform: translate(2px, 4px) rotate(45deg);
// 	  transform: translate(2px, 4px) rotate(45deg);
// }
// body.nav-active .menu-icon__line-right {
// width: 22px;
// float: right;
// -webkit-transform: translate(-3px, -3.5px) rotate(45deg);
// 	  transform: translate(-3px, -3.5px) rotate(45deg);
// }
// body.nav-active .menu-icon:hover .menu-icon__line-left,
// body.nav-active .menu-icon:hover .menu-icon__line-right {
// width: 23px;
// }
// body.nav-active .nav {
// visibility: visible;
// }
// body.nav-active .nav:before, body.nav-active .nav:after {
// -webkit-transform: translateX(0%) translateY(0%);
// 	  transform: translateX(0%) translateY(0%);
// border-radius: 0;
// }
// body.nav-active .nav:after {
// -webkit-transition-delay: .1s;
// 	  transition-delay: .1s;
// }
// body.nav-active .nav:before {
// -webkit-transition-delay: 0s;
// 	  transition-delay: 0s;
// }
// body.nav-active .menu-item {
// opacity: 1;
// -webkit-transform: translateX(0%);
// 	  transform: translateX(0%);
// -webkit-transition: opacity .3s ease, color .3s ease, -webkit-transform .3s ease;
// transition: opacity .3s ease, color .3s ease, -webkit-transform .3s ease;
// transition: opacity .3s ease, transform .3s ease, color .3s ease;
// transition: opacity .3s ease, transform .3s ease, color .3s ease, -webkit-transform .3s ease;
// }
// body.nav-active .menu-item:nth-child(0) {
// -webkit-transition-delay: 0.7s;
// 	  transition-delay: 0.7s;
// }
// body.nav-active .menu-item:nth-child(1) {
// -webkit-transition-delay: 0.8s;
// 	  transition-delay: 0.8s;
// }
// body.nav-active .menu-item:nth-child(2) {
// -webkit-transition-delay: 0.9s;
// 	  transition-delay: 0.9s;
// }
// body.nav-active .menu-item:nth-child(3) {
// -webkit-transition-delay: 1s;
// 	  transition-delay: 1s;
// }
// body.nav-active .menu-item:nth-child(4) {
// -webkit-transition-delay: 1.1s;
// 	  transition-delay: 1.1s;
// }
// body.nav-active .menu-item:nth-child(5) {
// -webkit-transition-delay: 1.2s;
// 	  transition-delay: 1.2s;
// }
// body.nav-active .menu-item:nth-child(6) {
// -webkit-transition-delay: 1.3s;
// 	  transition-delay: 1.3s;
// }
// body.nav-active .menu-item:nth-child(7) {
// -webkit-transition-delay: 1.4s;
// 	  transition-delay: 1.4s;
// }
// body.nav-active .menu-item:nth-child(8) {
// -webkit-transition-delay: 1.5s;
// 	  transition-delay: 1.5s;
// }
// body.nav-active .menu-item:nth-child(9) {
// -webkit-transition-delay: 1.6s;
// 	  transition-delay: 1.6s;
// }
// body.nav-active .menu-item:nth-child(10) {
// -webkit-transition-delay: 1.7s;
// 	  transition-delay: 1.7s;
// }

// @media screen and (max-width: 999px) {
// .nav_desktop {
//   display:none;
// }
// }

// @media screen and (min-width: 999px) {
// .menu-icon.hover-target {
// 	display: none;
// }
// }

// .nav__list-item_desktop a {
// position: relative;
// text-decoration: none;
// color: rgba(255,255,255,0.6);
// overflow: hidden;
// cursor: pointer;
// font-family: $font;
// font-weight: 600;
// z-index: 2;
// padding-left: 40px;
// padding-top: 5px;
// padding-bottom: 5px;
// display: inline-block;
// -webkit-transition: all 200ms linear;
// transition: all 200ms linear;
// }

// ul#menu-header {
// @media screen and (min-width: 999px) {
// width: auto;
// display: flex;
// }
//  a {
// 		text-transform: uppercase;
// 		font-size: 18px;
// 		font-weight: 600;
// 		&:hover {
// 			color: $color-primary;
// 		}
//  }
//  li {
// 	@media screen and (min-width: 999px) {
// 		margin: 15px 32px 0 0;
// 		vertical-align: middle;
// 		width: max-content;
// 		float: right;
// 	}
//  }
// }

// ul#menu-header {
// 	a {
// 	 &:hover {
// color: $color-secondary;
// 		}
// 	 }
// }

// .menu-item-has-children {
// 	position: relative;
// 	@media #{$tablet} {
// 	  padding-right: 30px;
// 	}
// 	  .dropdown-trigger {
// 		width: 15px;
// 		height: 15px;
// 		content: "";
// 		position: absolute;
// 		right: 2px;
// 		top: 22px;
// 		background-size: contain;
// 		background-position: center;
// 		background-repeat: no-repeat;
// 		background-image: url('./images/down-arrow.png');
// 		transition: $ease;
// 		cursor: pointer;
// 		@media #{$tablet} {
// 		  top: calc(50% - 7.5px);
// 		  right: 0px;
// 		}
// 		@media #{$small} {
// 			display:none;
// 		}
// 		  &.active {
// 			transform: rotate(180deg);
// 		  }
// 		  &:hover {
// 			transform: scale(1.1);
// 			  &.active {
// 			    transform: rotate(90deg);
// 			  }
// 		  }
// 	  }
// 	  .sub-menu {
// 		display: none;
// 		@media #{$tablet} {
// 			position: absolute;
// 			top: 57px;
// 			right: 0%;
// 			background-color: #fcde00d9;
// 			color: #1a345c;
// 			width: 400px;
// 			padding: 20px;
// 			margin: 0;
// 		}
// 		@media screen and (max-width: 999px) {
// 			display: inline-grid !important;
// 			float: left;
// 			width: 35%;
// 			padding: 0px !important;
// 			margin: 0px;
// 		}
// 		li {
// 			margin: 15px 32px 0 0;
// 			vertical-align: middle;
// 			width: -webkit-max-content;
// 			width: -moz-max-content;
// 			width: auto !important;
// 			float: left !important;
// 			text-align: left !important;
			
// 			a {
// 				text-transform: inherit !important;
// 				@media screen and (max-width: 999px) {
// 					font-size: 18px;
// 					opacity: .5;
// 				}
// 			&:hover {
// 				font-weight: bold !important;
// 				color: $color-secondary !important;
// 			}
// 		}
// 		}
// 	  } 
//   }


// Small misc styles not worth creating another file for.

body,
html {
	&.nav-active {
		overflow-y: hidden;
	}
}

.tablet-only {
	display: none;
	@media #{$tablet} {
		display: block;
	}
}

// 404 PAGE
.page-not-found {
	width: 100vw;
	max-width: 100%;
	background-color: $color-primary;
	.primary-container {
		padding: 20px 0px;
		@media #{$tablet} {
			padding: 200px 0px;
			min-height: 617px;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
		.primary-text {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			h1,
			p {
				color: $white;
			}
			.button {
				margin-top: 10px;
			}
		}
	}
}

// FLEX

.flex-container {
	display: flex;
	flex-wrap: nowrap;
	margin: auto;
	justify-content: center;
	width: 80%;
}

.flex-container > div {
	width: 100px;
	margin: 10px;
	text-align: center;
	line-height: 30px;
	font-size: 26px;
	color: $color-primary;
}

// SOCIAL ICONS

.social-icons {
	transition: $ease;
	img {
		&:hover {
			transform: scale(1.2);
			transition: $ease;
		}
	}
}

button#wpforms-submit-247 {
    width: 100%;
    background: $color-primary;
    border: none;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0px;
}

.wpforms-confirmation-container-full, div[submit-success]>.wpforms-confirmation-container-full:not(.wpforms-redirection-message) {
    background: #ced2d2;
    border: none;
}


// countdown timer

  ul#countdown {
	position: relative;
	width: 70%;
	margin: 0 auto;
	color: $white;
	border-width: 1px 0;
	overflow: hidden;
	font-family: $font;
	font-weight: bold;
	padding-top:2em;
	@media #{$small} {
		width: 100%;
	}
	li {
	  margin: 0 -3px 0 0;
	  padding: 0;
	  display: inline-block;
	  width: 24%;
	  font-size: 35px;
	  text-align: center;
	  color: $white;

	  .label {
		color: $white;
		font-size: 24px;
		text-transform: uppercase;
		@media #{$small} {
			font-size: 15px;
		}
		@media #{$tabletmid} {
			font-size: 17px;
		}
	  }
	}
	.number {
		color: $white;
	}
  }



// NEWS

/* Grid style */
.display-posts-listing.grid {
	display: grid;
	grid-gap: 0px;
}

.display-posts-listing.grid .title {
	display: block;
}

.display-posts-listing.grid img {
	display: block;
	max-width: 100%;
	height: auto;
	margin:auto;
}

@media (min-width: 600px) {
	.display-posts-listing.grid {
		grid-template-columns: repeat( 2, 1fr );
	}
}

@media (min-width: 1024px) {
	.display-posts-listing.grid {
		grid-template-columns: repeat( 3, 1fr );
	}
}

.single_news_article.block {
	margin-top: 5.5em;
}

.post-title {
    text-align: center;
    background: $color-primary;
    width: 100%;
	h1 {
		text-align: center;
	}
}

.full-size-news-image img {
    width: 100%;
    height: auto;
}
.single-post {
.primary-text {
	padding-bottom: 2em;
	img {
		width: 60%;
		object-fit: contain;
		margin: auto;
		justify-content: center;
		display: flex;
		height: fit-content;
		padding: 2em 0em;
	}
	p {
		text-align:center
	}
}
}


.btn_pagination a {
    background: $color-secondary;
    padding: 1em 2em;
    border-radius: 2em;
    margin: auto;
    justify-content: center;
    display: flex;
    width: fit-content;
    margin-top: 2em;
	transition: $ease;
	color: $white;
	font-size: 18px;
	&:hover {
		transition: $ease;
		font-weight:bold;
		background: $color-primary;
	}
}
.home {
	ul.display-posts-listing {
		a.title {
			display: none;
		}
	}
	span.excerpt {
		margin-top: 2em;
	}
}



// slick

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    transform: scale(.33);
    position: relative;
    background: $color-primary !important;
}

.gs_logo_area .swiper-pagination-bullet {
    height: 25px;
    width: 25px;
}

// hide button

.page-id-92 {
	button.btn.btn-primary {
		display: none;
	}
}

.page-id-165 {
	button.btn.btn-primary {
		display: none;
	}
}

// backgrounds 

div#gray_bg {
	background: $gray;
}

// phone number CTA page

h3.callus {
    text-align: center;
    margin: auto;
    justify-content: center;
	display: flex;
	@media #{$small} {
	display:block;
	  }
}

.page-id-165 {
	div#gray_bg {
	a {
		margin: 0px 10px !important;
		color: $color-primary !important;	
		transition: $ease;
		&:hover {
			text-decoration: underline;
			transition: $ease;
		}
	}
	}
}

a.underlinelink {
	color: $white;
	display: inline-block;
	position: relative;
	&::before {
		content: "";
		position: absolute;
		transition: width 0.25s ease-out;
		background-color: #fff;
		left: 0;
		bottom: 0;
		height: 1px;
		width: 0;
	}
}
a:hover::before {
	width: 100%;
}


.page-id-165 span, br {
	@media #{$small} {
  	  display: none;
	}
}

.page-id-165 {
.contact_info br {
    display: block;
}
}

section.instagram_section {
    margin-top: 7em;
    background: #EFEFF0;
    h1 {
        padding-bottom:1em;
    }
    h2 {
        font-size:36px;
        color: #5F686A;
        padding-bottom: 1em;
    }
}

section.facebook_section {
    background: #C1C1C3;
    h1 {
        padding-bottom:1em;
    }
    h2 {
        font-size:36px;
        color: #5F686A;
        padding-bottom: 1em;
    }
}

section.twitter_section {
    background: #efeff0;
    h1 {
        padding-bottom:1em;
    }
    h2 {
        font-size:36px;
        color: #5F686A;
        padding-bottom: 1em;
    }
}


a.sbi_load_btn {
    background: transparent !important;
}

span.sbi_btn_text {
    color: #5F686A;
    font-size: 20px !important;
}

.cff-photo-post {
    padding: 1em !important;
}

.ctf-tweets {
    @media only screen and (min-width: 1000px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    }
}

.ctf-item {
    padding: 1em !important;
    background: $white !important;
    @media only screen and (max-width: 999px) {
       margin: 1em 0em !important;
    }
}

#sb_instagram .sbi_photo_wrap {
    position: relative;
    background-position: center;
    background-size: cover;
    height: 280px;
}

a.center {
    text-align: center;
    margin: auto;
    justify-content: center;
    display: flex;
    &:hover {
        font-weight:bold;
        transition: $ease;
    }
} 

a.sbi_load_btn {
    background: transparent!important;
    color: transparent !important;
    transition: $ease;
    &:hover {
        font-weight:bold;
        transition: $ease;
    }
}
// Define all style variables here.

// Colours.
$white: #FFFFFF;
$gray: #EFEFF0;
$black: #5F686A;

// Brand Colours.
$color-primary: #E87200;
$color-secondary: #98989A;

// Fonts
@import url("https://use.typekit.net/kbh1vwg.css");
$heading: 'montserrat', sans-serif;
$font: 'montserrat', sans-serif !default;


// Media querys.
$small: "(max-width: 767px)";
$mobile: "(min-width: 750px)";
$tabletmid: "(max-width: 999px) and (min-width: 768px)";
$tablet: "(min-width: 1000px)";
$desktop: "(min-width: 1500px)";
$large: "(min-width: 1600px)";

// Misc.
$max-width: 1000px;
$shadow: 0 5px 10px 0 rgba(0,0,0,.05);
$ease: ease all 0.3s;
$transition-time: 750ms;

// Default classes, add these to elements to prevent repeating yourself.
.default-background {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.default-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
 
// SCSS includes.
@mixin default-psuedo {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  transition: $ease;
}


form#searchform {
    &:hover{
        border: 1px solid ;
        box-sizing: inherit;
    }
    input#searchsubmit {
        display: none;
    }
    .screen-reader-text {
        border: 0;
        clip: initial;
        -webkit-clip-path: 0;
        clip-path: initial;
        height: 1px;
        margin: -1px;
        overflow: inherit;
        width: 100%; 
        padding: .3em 1em;
        font-weight: light;
       }
       input#s {
        padding: 1px;
        font-size: 16px;
        border: 1px solid ;

    }
}
.flip_card_section {
  overflow: hidden;
.btn-group {
  margin: auto;
  justify-content: center;
  display: flex;
  padding-bottom: 4em;
  padding-top: 2em;
  @media #{$small} {
    display: block;
  }
  button.btn.btn-primary {
  padding: 13px 40px;
    border-radius: 2em;
    font-size: 20px;
    font-weight: normal;
    transition: all .3s ease;
    @media #{$small} {
      width:100%;
    }
}
  button.btn.btn-secondary {
    font-size: 20px;
    font-weight: normal;
    border-radius: 1em;
    padding: 12px 50px;
    border: none;
    transition: all .3s ease;
    border-radius: 2em;
    @media #{$small} {
      width:100%;
    }
}
}
}
.flip-cards-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.col-md-4 {
  padding: 0;
  margin: 0;
}

.flip-card {
  width: 100%;
  height: 620px;
  perspective: 1000px;
  margin: 0;
  cursor: pointer;
  background-position: center;
  background-size: cover;
  transition: transform 0.6s; /* Add the transition property for smooth animation */
}

.flip-card .card-front,
.flip-card .card-back {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  backface-visibility: hidden;
  transition: transform 0.6s;
}

.card-back {
  background-color: #bada55;
  transform: rotateY(180deg);
  display: block;
  padding-left: 5em;
  padding-top: 5em;
  justify-content: flex-start; /* Align text to the left */
  align-items: center; /* Center vertically */
}

.flip-card {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  .card-back {
  background-color: $color-primary;
      transform: rotateY(180deg);
      p {
        color:$white;
        position: relative;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        text-align: left;
      }
 }
}



.flip-card.flipped .card-front {
  transform: rotateY(180deg);
}

.flip-card.flipped .card-back {
  transform: rotateY(0deg);
}

.page-id-92 {
.flip_card_section {
  @media only screen and (min-width: 767px) {
   margin-top: 6em;
  }
}
}
.gallery_grid {
    img.img-fluid {
        width: 100%;
        padding: 0;
        margin: 0;
        filter: grayscale(0);
       &:hover {
        filter: grayscale(1);
    }
  }
}
.primary-container.block.full_width_image {
    margin-bottom: 0;
    width: 100%;
    max-width: 100%; 
    padding: 0;
    img {
        width: 100%;
        height:auto;
    }
}
.primary-container.block.info_box {
    width: 100%;
    max-width: 100%;
    margin: auto;
    justify-content: center;
    text-align: center;
    background: $color-primary;
    a {
        color: $color-secondary;
    }
    .copy_content {
        padding-bottom: 1.5em;
    }
    h1, h2, h3, h4, h5, h6, strong, p, b {
    color: $white;
    }
    .images {
        padding: 1em 0em;
        background: $white;
    img {
        padding: .5em;
        width: 33%;
        &:nth-child(1){
            padding-left:0;
        }
        &:nth-child(3){
            padding-right:0;
         }
        }
    }
}
.block.onecol {
    padding: 7em 0em;
    margin-bottom:0;
    background-repeat: repeat;
    background-position: center;
    background-size: contain;
    background: $white;
    @media #{$small} {
      padding: 4em 1.5em;
      background-size: cover;
    }
    h1, h2, h3, h4, h5, h6, p, strong, b, em, ul, li {
        text-align: center;
    }
    p, strong, b, em, ul, li {
      color: #5F686A;
      font-size:20px;
      @media #{$small} {
        font-size:17px;
      }
  }
    a {
      margin: auto;
      justify-content: center;
      text-align: center;
      display: flex;
      color: $white;
      opacity: 1;
  }
  button.btn.btn-primary {
    border-radius: 2em;
    margin-right: 0em;
    font-size: 20px;
    color:$white;
    margin-top: 1.5em;
    transition: $ease;
    &:hover {
      background: $color-secondary;
    }
}
    h1, h2, h3, h4, h5, h6 {
        padding-bottom:20px;
        color: $color-primary;
    }
  li {
    text-align: center;
    font-size: 22px;
    padding-bottom: 5px;
}
}


// background
.block.onecol_bg {
  text-align: center;
  padding: 5em 0em;
  @media #{$small} {
    padding: 2em 0em;
  }
  a {
    color: $color-secondary;
  &:hover {
    color:$white;
  }  
  }
  h3 {
    padding: 0em 0em 15px 0em;
  }
  
}



// dark background

div#dark_bg {
  background: #5F686A;
  p {
    color: $white;
  }
  button.btn.btn-primary {
    display: none;
}
}
.parallax-container {
    position: relative;
    width: 100%;
    height: 765px; /* Set the height of the parallax container */
    overflow: hidden;
    @media #{$small} {
      position: relative;
      width: 100%;
      height: 60vh;
      overflow: hidden;
  }
  }

  .parallax-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('https://dev.portfoliodesign.net/belmontlabels/wp-content/uploads/2023/07/parallax.png'); /* Replace 'your-image.jpg' with the URL of your image */
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    height: 100vh;
  }

  .parallax-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    text-align: center;
    font-size: 24px;
  }
.block.default-center.default-background.testimonial_block {
    text-align: center;
    padding: 5em;
    background: #F5F4F4;
    @media #{$small} {
        padding: 2em;
    }
   h3 {
    padding-bottom:1em;
   }
    p {
        color: $color-primary;
        padding-bottom:2em;
    }
    .slick-dots li button {
        font-size: 5em;
        line-height: 0;
        display: block;
        width: 16px;
        height: 16px;
        padding: 5px;
        cursor: pointer;
        color: transparent;
        border: 0;
        outline: none;
        background: transparent;
        border: 2px solid $white;
        border-radius: 10px;
    }

    .slick-dots li.slick-active button {
        background: $color-secondary;
        border:none;
    }

}



.slick-arrow {
    display: none !important;
}
.shortcode_block {
  text-align: center;
  max-width:100%;
  width:100%;
  .shortcode_content {
    background: $color-secondary;
    padding:5em 0em;
    margin-top: -1em;
    @media #{$small} {
      padding: 3em 0;
    }
    h5 {
      padding-bottom:1em;
    }
    span {
      color: $white;
    }
    p {
      font-size: 27px;
      font-weight: 700;
      text-transform: uppercase;
      @media #{$small} {
        font-size: 16px;
        padding: 0em 1em;
      }
    }
  }
}

.page-id-82 {
  .shortcode_content {
    display: none;
  }
}
.pink-column {
    height: 855px;
    background-size: cover;
    background-position: center;
    @media #{$small} {
        height: 500px;
    }
    h3, p {
        color: $white;
        text-align: left;
    }
    h3 {
       padding:1em 0 .5em;
    }
    button.btn.btn-primary {
        padding: 10px 4em;
        font-weight: 500;
    }
  }

  .image-column {
    background-size: cover;
    height: 855px;
    background-position: center;
    @media #{$small} {
        height: 500px;
    }
    h4 {
        color: $white;
        padding-top:2em;
    }
    button.btn.btn-primary {
        padding: 10px 4em;
        font-weight: 500;
    }
  }

  .row.align-items-center {
    background: $color-secondary;
    margin: 0 !important;
    padding: 0 !important
}

img.white_logo {
    @media #{$small} {
       width: 50%;
    }
}

.container.first.d-flex.flex-column.justify-content-center.align-items-center {
    margin: auto;
    text-align: center !important;
    align-items: center !important;
    vertical-align: middle;
    width: 50%;
    padding: 0;
    @media #{$small} {
        width: 100%;
        padding: 2em;
    }
    a {
        color: #fff;
        font-size: 20px;
        &:hover {
            text-decoration:underline;
        }
    }
}
.header_layout-1,
.header_layout-3 {
		width: 100%;
		max-width: 100%;
		margin-bottom: 0;
		background-position: 50%;
	    height: 425px;
		background-attachment: initial;
		margin-top: 7em;
		// background: #00000045;
		background-position: center;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		@media #{$small} {
			background-repeat: no-repeat;
		    background-attachment: scroll;
			background-size: cover;
			background-position: center;
			margin-top: 4em;
		}
	.primary_container {
		padding: 20px;
		display: flex;
		justify-content: center;
		.header_text {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			padding-top:200px;
			@media #{$small} {
				padding-top: 160px;
			}
			p {
				margin: auto;
				color: $color-primary;
				font-size:33px;
				margin-top: 5px;
				@media #{$small} {
					text-align:center;
					line-height: 30px;
					font-size: 30px;
				}
			}
			h1 {
				font-family: $heading;
				color: $color-primary;
				text-align: center;
				font-size: 27px;
				@media #{$small} {
					font-size: 17px;
				}
			}
			.text_button {
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				margin-bottom: 20px;
				@media #{$tablet} {
					flex-direction: row;
					justify-content: space-between;
				}
				@media #{$small} {
					margin-bottom: 0px;
				}
				h1 {
					font-family: $heading;
					color: $color-primary;
					text-align: center;
					font-size: 27px;
					@media #{$tablet} {
						text-align: center;
						margin-bottom: 0px;
					}
				}

				.button {
					border-color: $black;
					color: $black;
					&:hover {
						border-color: $color-primary;
						color: $color-primary;
					}
				}
			}
			h2 {
				text-align: center;
				padding-top: 10px;
				color: $color-primary;
				@media #{$tablet} {
					text-align: center;
				}
			}
		}
	}
	&.blog-header {
		.primary_container {
			.header_text {
				.text_button {
					margin-bottom: 0px;
				}
			}
		}
	}
}


/* Global Styles */

/* Video Overlay */

#overlay {
height:fit-content;
height: -webkit-fill-available;
left:0;
position:relative;
top:0;
transition:background-color 300ms ease;
width:100%;
margin-top: -10px;
h1 {
  left: 0;
    right: 0;

}
}

.fade { background-color:rgba(0,0,0,.85) !important; }


/* Hero Video + Fallback */

#hero-vid {
backface-visibility:hidden;
background-size:cover;
bottom:0;
height:auto;
min-height:100%;
min-width:100%;
perspective:1000;
position: inherit;
    right: 0;
    width: 100%;
    z-index: -1;
    transform: translate3d(0px, 5px, 0px) !important;
}

#hero-pic {
display:block;
height:auto;
width:100%;
}

#state {
bottom:0;
cursor:pointer;
font-size:2.25rem;
left:0;
line-height:1;
padding:2rem 2.5rem 1.65rem;
position:absolute;
}


/* Content Styles */

#title {
  position: absolute;
  top: 80%;
  left: 50%;
  z-index: 1;
  text-align: center;
  width: 100%;
  opacity: 1;
  left:0;
  right:0;
  transition: opacity 0.5s;
}
#title.loaded {
  opacity: 1;
  left:0;
  right:0;
}

#overlay h1.loaded {
  opacity: 1;
  left:0;
  right:0;
}

#title h1 {
  color: #fff;
  left: 0;
    right: 0;

}

#content {
background-color:$gray;
padding:2.5rem;
position: inherit;
z-index:1;
text-align:center;
h2 {
  padding-bottom:20px;
}
}

#content h2 {
color: $color-primary;
}



/* Media Queries */

@media only screen and (max-width:768px) {
  #overlay {
    height: 76vh;
    margin-top: -3em;
}
}



@media only screen and (min-width: 769px) and (max-width: 1275px)  {
  #overlay {
      height: 85vh;
  }
}

@media only screen and (min-width: 1000px) {
  div#content {
    padding: 7em 0em;
    }
  }

  @media only screen and (min-width: 1200px) and (max-width: 1500px)  {

    button.btn.btn-primary {
      font-size: 25px;
  }

  button.btn.btn-secondary {
    font-size: 23px;
}

  }
  